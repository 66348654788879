import React from 'react';
import './assets/css/style.css';
import Dashboard from './components/Dashboard';


function App() {
  return (
    <div>
      <Dashboard />
    </div>
  )
}

export default App;