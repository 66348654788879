import { Outlet } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import logo from '../assets/img/logo.png';
import hero from '../assets/img/hero-img.png';
import about from '../assets/img/about.jpg';
import build from '../assets/img/building.svg';
import bank from '../assets/img/bank.svg';
import cloud from '../assets/img/cloud.svg';
import database from '../assets/img/database.svg';
import address from '../assets/img/map-pin.svg';
import mail from '../assets/img/mail.svg';
import phone from '../assets/img/phone-call.svg';

import React, { useState, useRef, useEffect } from 'react';

function Dashboard() {
    const [mobile, setMobile] = useState(false);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_tanqp1l', 'template_i0dhoci', form.current, 'jv4IPf9zdi0q5h9Ft');
        setTimeout(
            function notify() {
                toast.success('Successfully Submitted.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }, 800
        );

        e.target.reset();

    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>

            {/* Header */}
            <header>
                <div className="container header">
                    <Link to="#" className="logo" smooth><img src={logo} alt="" className="" /></Link>

                    <nav className="navbar">
                        <ul className={mobile ? 'nav-links-mobile' : 'nav-links'}
                            onClick={() => setMobile(false)}>
                            <Link to="#" smooth className='home'><li>Home</li></Link>
                            <Link to="#services" smooth className='services'><li>Services</li></Link>
                            <Link to="#about" smooth className='about'><li>About</li></Link>
                            <Link to="#contact" smooth className='contact'><li>Contact Us</li></Link>
                        </ul>
                        <button className='menu-icon' onClick={() => setMobile(!mobile)}>
                            {mobile ? (
                                <i className='fa fa-times'></i>
                            ) : (
                                <i className='fas fa-bars'></i>
                            )}
                        </button>
                    </nav>
                </div>
            </header>
            <Outlet />

            {/* Hero Section */}
            <section id="hero" className="d-flex align-items-center">
                <div className="container-hero">
                    <div className="row">
                        <div className="left-hero">
                            <h1>We provide IT consultancy, Training,
                                and Solutions to Schools and Financial Organisations with
                                Cloud Infrastructure Automation</h1>
                            <h2>We develop innovative products and services for
                                schools and financial organisations.</h2>
                            <div className="d-flex">
                                <Link to="#contact" className="btn" smooth >Contact Us</Link>
                            </div>
                        </div>
                        <Outlet />
                        <div className="right-hero">
                            <img src={hero} className="img-hero" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <main id='main'>
                {/* Services Section */}
                <section id="services">
                    <div className="container-ser">

                        <div className="section-title">
                            <h2>Services</h2>
                        </div>

                        <div className="row">
                            <div className="d-flex item">
                                <div className="icon-box">
                                    <div className="icon">
                                        <i><img src={build} alt="" width="150" height="62" /></i>
                                    </div>
                                    <div className='icon-post'>
                                        <h4>SCHOOL MANAGEMENT SOLUTION</h4>
                                        <p>BlizSchools is a platform created to help schools run
                                            smoothly and effectively by digitizing and automating
                                            their various academic and administrative tasks.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex item">
                                <div className="icon-box">
                                    <div className="icon">
                                        <i><img src={cloud} alt="" width="150" height="62" /></i>
                                    </div>
                                    <div className='icon-post'>
                                        <h4>CLOUD INFRASTRUCTURE AUTOMATION</h4>
                                        <p>Dynamowit will help organizations eliminate manual &
                                            repetitive efforts, inefficiency and errors inherent with
                                            manual processes by driving IT efficiency & enabling an
                                            organization’s digital transformation.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex item">
                                <div className="icon-box">
                                    <div className="icon">
                                        <i><img src={bank} alt="" width="150" height="62" /></i>
                                    </div>
                                    <div className='icon-post'>
                                        <h4>DIGITAL BANKING SOLUTION </h4>
                                        <p>Dynamowit provides automated traditional banking
                                            services through digital banking. Customers will be
                                            able to access banking services and products through
                                            an electronic/online platform thanks to BlizFinance.
                                            With our product line of Internet Banking & Core
                                            Banking platforms, this application will digitize banking
                                            activities.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex item">
                                <div className="icon-box">
                                    <div className="icon">
                                        <i><img src={database} alt="" width="150" height="62" /></i>
                                    </div>
                                    <div className='icon-post'>
                                        <h4>POS SOLUTION</h4>
                                        <p>Dynamowit helps businesses by incorporating mobile POS
                                            features and contactless payment options, ecommerce
                                            integration capabilities, and more.We help our clients drive
                                            growth by providing them with tools to simplify their
                                            business processes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* About Section */}
                <section id="about">
                    <div className="container-ser">
                        <div className="section-title">
                            <h2>About</h2>
                        </div>
                        <div className="row">
                            <div className="d-flex item">
                                <img src={about} className="img-about" alt="" />
                            </div>
                            <div className="d-flex item about-note">
                                <span><strong>Dynamowit </strong>is legally registered as Dynamo WIT LTD under
                                    the Corporate Affair Commission *CAC* in
                                    Nigeria,
                                    with RC No: 1729155.<br /> </span>
                                <span>
                                    We provide IT consultancy, training, and Solutions to Schools and
                                    Financial Organisations
                                    with cloud infrastructure
                                    automation.<br /> </span>
                                <span>
                                    Our team comprises of strong resources with over 15 years
                                    experience combined with most of it in
                                    educational and
                                    financial solution development, digital transformation
                                    across schools and financial
                                    organisations in Nigeria.<br />
                                </span>
                                <span>
                                    Our resources are part of the support structure driving
                                    most of the digital transformation in Nigeria.
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Contact Section */}
                <section id="contact">
                    <div className="container-ser">

                        <div className="section-title">
                            <h2>Contact</h2>
                        </div>

                        <div className="row">

                            <div className="d-flex item-1">
                                <div className="info">
                                    <div className="address">
                                        <i class="icofont-google-map">
                                            <img src={address} alt='' />
                                        </i>
                                        <h4>Location:</h4>
                                        <p>1, Ifesowapo Street, Ogudu, Lagos State.</p>
                                    </div>

                                    <div className="email">
                                        <i className="icofont-envelope">
                                            <img src={mail} alt='' />
                                        </i>
                                        <h4>Email:</h4>
                                        <p>info@dynamowit.com - 24/7 online Support</p>
                                    </div>

                                    <div className="phone">
                                        <i className="icofont-phone">
                                            <img src={phone} alt='' />
                                        </i>
                                        <h4>Call:</h4>
                                        <p>+234 808 131 1789 / Monday - Sunday</p>
                                    </div>
                                    <iframe title='map' width="100%" height="290px" id="gmap_canvas" src="https://maps.google.com/maps?q=Ojota/Ogudu%20105102%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                </div>

                            </div>

                            <div className="d-flex item-2">
                                <form className="email-form" ref={form} onSubmit={sendEmail}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label for="name">Full Name</label>
                                            <input type="text" name="user_name" className="form-control" id="name" required />
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="name">Email</label>
                                            <input type="user_email" className="form-control" name="user_email" id="email" required />

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="name">Subject</label>
                                        <input type="text" className="form-control" name="subject" id="subject" required />
                                    </div>
                                    <div class="form-group">
                                        <label for="name">Message</label>
                                        <textarea className="form-control" name="message" rows={10} cols={10} required></textarea>
                                    </div>
                                    <input type='submit' value='Send Message' className="btn btn-contact" />
                                </form>
                            </div>

                        </div>

                    </div>
                </section>

            </main>

            {/* Footer Section */}
            <footer id="footer">
                <div className="container-ser footer-bottom clearfix">
                    <div className='social-media'>
                        <Link to="//www.twitter.com/dynamowit" target='_blank' smooth>
                            <i class="fa-brands fa-twitter"></i>
                        </Link>
                        <Link to={"//www.facebook.com/dynamowit"} target='_blank' smooth>
                            <i class="fa-brands fa-facebook"></i>
                        </Link>
                        <Link to={"//www.instagram.com/dynamowit"} target='_blank' smooth>
                            <i class="fa-brands fa-instagram"></i>
                        </Link>
                        <Link to={"//www.youtube.com/channel/UCmKCDb_miaiMt2QYMWQwtuw/featured"} target='_blank' smooth>
                            <i class="fa-brands fa-youtube"></i>
                        </Link>
                    </div>
        
                    <div className="copyright">
                        Copyright &copy; 2022 <strong><span>Dynamowit</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </>
    )
}

export default Dashboard
